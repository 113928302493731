<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
        <div class="flex a-center"
             slot="extra">
          <Icon custom="i-icon icon-jingshi"
                size="16"
                color="#FF9B77"></Icon>
          达标算法：必须在上下班时间段内清运，才算达标
        </div>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <template v-if="currentTabIndex===1">
              <Select v-model="selectType"
                      placeholder="查询分类"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in selectTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <Input v-model="planName"
                   placeholder="计划名称"
                   class="m-r-10"
                   style="width:160px" />
            <Input v-model="cleanPoint"
                   placeholder="清运点"
                   class="m-r-10"
                   style="width:160px" />
            <template v-if="currentTabIndex===0">
              <Input v-model="carNumber"
                     placeholder="车牌号"
                     class="m-r-10"
                     style="width:160px" />
            </template>
            <template v-if="currentTabIndex===1">
              <DatePicker type="daterange"
                          :start-date="new Date()"
                          placeholder="请选择时间范围"
                          @on-change="onChangeDate"
                          style="width:260px"
                          v-if="selectType===1"></DatePicker>
              <DatePicker type="month"
                          placeholder="选择月份"
                          @on-change="onChangeDate"
                          style="width:160px"
                          v-if="selectType===2"></DatePicker>
            </template>
          </div>
          <div class="flex">
            <template v-if="currentTabIndex===1">
              <template v-if="selectType===1">
                <Button type="primary"
                        class="m-l-10">今天</Button>
                <Button type="primary"
                        class="m-l-10">昨天</Button>
                <Button type="primary"
                        class="m-l-10">近三天</Button>
              </template>
              <template v-if="selectType===2">
                <Button type="primary"
                        class="m-l-10">本月</Button>
                <Button type="primary"
                        class="m-l-10">上月</Button>
              </template>
            </template>
            <Button type="primary"
                    class="m-l-10">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-if="currentTabIndex===0">
        <div class="flex">
          <div class="left">
            <p>清运日历</p>
            <div class="calender">
              <el-calendar class="calendar-box">
                <template slot="dateCell"
                          slot-scope="{date, data}">
                  <p>
                    {{ data.day.split('-')[2] }}
                  </p>
                </template>
              </el-calendar>
              <ul class="calender-bottom">
                <li>
                  <div class="calender-color success"></div>
                  <span>达标</span>
                </li>
                <li>
                  <div class="calender-color warning"></div>
                  <span>未达标</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="table flex-1 m-l-15">
            <Table ref="table"
                   :height="tableHeight1"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   @on-selection-change="onChangeSelect"
                   @on-sort-change="onChangeSort">
              <!-- <template slot-scope="{ row }"
                        slot="operation">
                <Button @click.stop=""
                        type="text">处理</Button>
              </template> -->
            </Table>
            <Page class="m-t-10 m-l-10"
                  :total="totalPage"
                  :current="pageNum"
                  :page-size="pageSize"
                  show-total
                  show-sizer
                  show-elevator
                  @on-change="onPageChange"
                  @on-page-size-change="onPageSizeChange" />
          </div>
        </div>
      </template>
      <template v-if="currentTabIndex===1">
        <div class="p-15 bg-white">
          <Tabs v-model="currentCleanTabIndex"
                class="tabs"
                key="child1">
            <TabPane v-for="item in cleanTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
          <div class="table m-t-15">
            <Table ref="table"
                   :height="tableHeight2"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   @on-selection-change="onChangeSelect"
                   @on-sort-change="onChangeSort">
              <!-- <template slot-scope="{ row }"
                        slot="operation">
                <Button @click.stop=""
                        type="text">处理</Button>
              </template> -->
            </Table>
            <Page class="m-t-10 m-l-10"
                  :total="totalPage"
                  :current="pageNum"
                  :page-size="pageSize"
                  show-total
                  show-sizer
                  show-elevator
                  @on-change="onPageChange"
                  @on-page-size-change="onPageSizeChange" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '日历看板'
        },
        {
          name: '自定义查询'
        },
      ],
      selectType: 1,
      selectTypeArr: [
        {
          id: 1,
          name: '按天查询'
        },
        {
          id: 2,
          name: '按月查询'
        },
      ],
      planName: '',
      cleanPoint: '',
      carNumber: '',
      date: [],
      currentCleanTabIndex: 0,
      cleanTabArr: [
        {
          name: '清运达标汇总'
        },
        {
          name: '清运达标明细'
        }
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight1: 0,
      tableHeight2: 0,
      tableLoading: false,
      columnsClean: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '清运点',
          key: 'manageName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '设施类型',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '清运频次',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '达标情况',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车牌号',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '清运时间',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '清运桶次',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsCleanSummary: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '清运点',
          key: 'manageName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '设施类型',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '开始日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '结束日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业周期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '应达标天数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '实际达标天数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '未达标天数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '应清运次数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '实清运次数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '遗漏次数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsCleanDetail: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '清运点',
          key: 'manageName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '设施类型',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '开始日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '结束日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业周期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '应达标天数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '实际达标天数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '未达标天数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '应清运次数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '实清运次数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '遗漏次数',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      data: [],
      selectArr: [],
    };
  },
  computed: {
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsClean
          break;
        case 1:
          switch (this.currentCleanTabIndex) {
            case 0:
              columns = this.columnsCleanSummary
              break;
            case 1:
              columns = this.columnsCleanDetail
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    init () {
      this.tableHeight1 = window.innerHeight - 280
      this.tableHeight2 = window.innerHeight - 370
      window.onresize = () => {
        this.tableHeight1 = window.innerHeight - 280
        this.tableHeight2 = window.innerHeight - 370
      }
      this.getList()
    },
    onClickSet () {

    },
    onChangeDate (date) {
      this.date = date
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {

    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs";
@import "@/scss/iviewCssReset/adminCalendar";
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    margin-top: 16px;
    .left {
      width: 360px;
      height: calc(100vh - 225px);
      padding: 16px;
      background-color: #fff;
      overflow: auto;
      .calender {
        .calender-bottom {
          display: flex;
          li {
            display: flex;
            align-items: center;
            margin-right: 10px;
            .calender-color {
              width: 20px;
              height: 14px;
              margin-right: 10px;
            }
          }
        }
        ::v-deep {
          .success {
            background-color: #19be6b;
            color: #fff;
          }
          .warning {
            background-color: #ff9900;
            color: #fff;
          }
          .exception {
            background-color: #ed4014;
            color: #fff;
          }
        }
      }
      .left-list {
        display: flex;
        .left-list-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            color: #a9aab5;
            line-height: 36px;
          }
          .left-list-icon {
            color: #0079fe;
            padding: 5px;
            background-color: #f2f2f2;
            border-radius: 50%;
          }
        }
      }
    }
    .table {
      background-color: #fff;
    }
  }
}
</style>